import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import 'swiper/swiper-bundle.css'

export default {
  name: "SuccessSpeaks",
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      show: false,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          785: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    //console.log(this.swiper)

    this.getVideoDetails();
  },
  methods: {
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    getVideoDetails() {
      $.ajax({
        type: "POST",
        dataType: 'json',
        url: this.$js.webservice + "GetDeployedVideo",
        data: { token: "webkey" },
        success: (response) => {
          if (response.status == "SUCCESS") {
            this.slideVideo = response.text;
            this.show = true;
          }
        },
        error: function (XMLHttpRequest) { }
      });
    },
    getH() {
      $('.text-center').css({
        'height': $('iframe').height()
      })
      $(window).resize(function () {
        $('.text-center').css({
          'height': $('iframe').height()
        })
      })
    }
  },
}